<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务中心</a-breadcrumb-item>
            <a-breadcrumb-item>业绩目标</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <a-tabs :animated="false" :activeKey="acitveKey" @change="changeTab">
                <a-tab-pane tab="校区业绩目标" key="1">
                    <campusTarget/>
                </a-tab-pane>
                <a-tab-pane tab="员工业绩目标" key="2">
                    <StaffTarget/>
                </a-tab-pane>
                <a-tab-pane tab="校区课消目标" key="3">
                    <campusClassTarget/>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>
<script>
import StaffTarget from './modal/StaffTarget'
import campusTarget from './modal/campusTarget'
import campusClassTarget from './modal/campusClassTarget'
export default {
    name:'bizGoals',
    provide() {
    return {
      parent: this
    }
  },
  data(){
      return{
          acitveKey: '1',
      }
  },
  components: {
      StaffTarget,
      campusTarget,
      campusClassTarget
  },
  methods: {
      changeTab(key) {
      this.acitveKey = key
    }
  }
}
</script>