<template>
    <div class="staffTaget">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="showIcon">
              <a-button @click="toShow" icon="edit">编辑</a-button>
            </a-form-item>
            <a-form-item v-if="!showIcon">
              <a-button @click="toSave" icon="check">保存</a-button>
            </a-form-item>
            <a-form-item v-if="!showIcon">
              <a-button @click="cancalShow" icon="close">取消</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search" style="padding-bottom:10px;">
          <a-form layout='inline'>
            <a-form-item>
                <a-select :value="year" @change="handleChangeTime">
                    <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-select style="width: 160px" placeholder="选择校区" showSearch :filterOption="filterOption" allowClear v-model="searchParams.search.obj_id">
                  <a-select-option v-for="(item,index) in studios" :key="index"  :value="item.studio_id">{{item.filter_name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="achievement_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 2300 }">
          <template slot="yeartarget" slot-scope="text,record">
            <span>{{ text | filterNum }}</span>
          </template>
          <template slot="firstQuarter" slot-scope="text,record">
            <span>{{ text | filterNum }}</span>
          </template>
          <template slot="secondQuarter" slot-scope="text,record">
            <span>{{ text | filterNum }}</span>
          </template>
          <template slot="thirdQuarter" slot-scope="text,record">
            <span>{{ text | filterNum }}</span>
          </template>
          <template slot="fourthQuarter" slot-scope="text,record">
            <span>{{ text | filterNum }}</span>
          </template>
          <template v-for="col in ['january', 'february', 'march', 'april' , 'may' , 'june' , 'july' , 'august' , 'september' , 'october' , 'november' , 'december' ]" :slot="col" slot-scope="text,record">
              <div :key="col">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  type='number'
                  @change="e => handleChange(e.target.value, record.achievement_id, col)"
                  @blur="e => handleBlur(e.target.value, record.key, col)"
                />
                <template v-else>{{ text | filterNum }}</template>
              </div>
          </template>
        </a-table>
      </div>
    </div>
</template>
<script>

const columns = [
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
  { title: '', width:'120px', dataIndex: 'name', key: 'name', fixed: 'left',ellipsis:true},
  { title: '全年', dataIndex: 'yeartarget', key: 'yeartarget',scopedSlots: { customRender: 'yeartarget' },align:'right'},
  { title: '第一季度', dataIndex: 'firstQuarter', key: 'firstQuarter',scopedSlots: { customRender: 'firstQuarter' },align:'right'},
  { title: '1月', dataIndex: 'january', key: '1' ,scopedSlots: { customRender: 'january' },align:'right'},
  { title: '2月', dataIndex: 'february', key: '2' ,scopedSlots: { customRender: 'february' },align:'right'},
  { title: '3月', dataIndex: 'march', key: '3' ,scopedSlots: { customRender: 'march' },align:'right'},
  { title: '第二季度', dataIndex: 'secondQuarter', key: 'secondQuarter',scopedSlots: { customRender: 'secondQuarter' },align:'right'},
  { title: '4月', dataIndex: 'april', key: '5' ,scopedSlots: { customRender: 'april' },align:'right'},
  { title: '5月', dataIndex: 'may', key: '6' ,scopedSlots: { customRender: 'may' },align:'right'},
  { title: '6月', dataIndex: 'june', key: '7' ,scopedSlots: { customRender: 'june' },align:'right'},
  { title: '第三季度', dataIndex: 'thirdQuarter', key: 'thirdQuarter',scopedSlots: { customRender: 'thirdQuarter' },align:'right'},
  { title: '7月', dataIndex: 'july', key: '9' ,scopedSlots: { customRender: 'july' },align:'right'},
  { title: '8月', dataIndex: 'august', key: '10' ,scopedSlots: { customRender: 'august' },align:'right'},
  { title: '9月', dataIndex: 'september', key: '11' ,scopedSlots: { customRender: 'september' },align:'right'},
  { title: '第四季度', dataIndex: 'fourthQuarter', key: 'fourthQuarter',scopedSlots: { customRender: 'fourthQuarter' },align:'right'},
  { title: '10月', dataIndex: 'october', key: '13' ,scopedSlots: { customRender: 'october' },align:'right'},
  { title: '11月', dataIndex: 'november', key: '14' ,scopedSlots: { customRender: 'november' },align:'right'},
  { title: '12月', dataIndex: 'december', key: '15' ,scopedSlots: { customRender: 'december' },align:'right'},
]

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from "moment"
import cItems from '@/utils/items'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
export default {
    provide() {
      return {
        parent: this
      }
    },
    data(){
        return{
            loading:false,
            exportLoading:false,
            open: false,
            year: '',
            yearList:[],
            departmentList:[],
            studios:[],
            loading: false,
            showIcon: true,
            columns,
            pageParams:{
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            list:[],
            moneyType:cItems.achievementTarget,
            authType:['finance','bizgoals'],
        }
    },
    mixins: [ tableMixins , authority ],
    components: { moment , DatePicker},
    filters: {
      filterNum: function(val) {
        val = val.toString().replace(/\$|\,/g,'');
        if(isNaN(val)) {
          val = "0";  
        } 
        let sign = (val == (val = Math.abs(val)));
        val = Math.floor(val*100+0.50000000001);
        let cents = val%100;
        val = Math.floor(val/100).toString();
        if(cents<10) {
          cents = "0" + cents
        }
        for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
            val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
        }

        return (((sign)?'':'-') + val + '.' + cents);
      }
    },
    mounted(){
      let defaultYear = 2017
      let nowYear = Number(moment().format('YYYY'))
      let yearList = [nowYear]
      for(let i=0; i <= nowYear-defaultYear; i++){
          nowYear-=1
          yearList.push(nowYear)
      }
      this.yearList = yearList
      this.year = Number(moment().format('YYYY'))
      this.getDepartment()
      this.getStudio()
    },
    methods:{
        filterOption(input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        notBeforeYear(date){
          const today = new Date();
          return date >= today;
        },
        async getList() {
            if(!this.canIndex){
                this.$message.warning('您暂无权限!!')
                return false
            }
            this.loading = true
            let res = await this.$store.dispatch('settingSystemEducationAchievementAction', {search:this.searchParams.search} )
            let list = res.data;
            list.forEach((item,index)=>{
                item.editable = false;
                item.firstQuarter = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march);
                item.secondQuarter = parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june);
                item.thirdQuarter = parseFloat(item.july) + parseFloat(item.august) + parseFloat(item.september);
                item.fourthQuarter = parseFloat(item.october) + parseFloat(item.november) + parseFloat(item.december);
                item.yeartarget = parseFloat(item.firstQuarter) + parseFloat(item.secondQuarter) + parseFloat(item.thirdQuarter) + parseFloat(item.fourthQuarter)
                item.firstQuarter = item.firstQuarter.toFixed(2);
                item.secondQuarter = item.secondQuarter.toFixed(2);
                item.thirdQuarter = item.thirdQuarter.toFixed(2);
                item.fourthQuarter = item.fourthQuarter.toFixed(2);
                item.yeartarget = item.yeartarget.toFixed(2);
            })
            this.list = list;
            this.loading = false
        },
        async getDepartment(){
          let res = await this.$store.dispatch('employeeOrganizationAction', {})
          this.departmentList = res.data;
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        handleChangeTime(e){
          this.year = e
          this.searchParams.search.year = e
        },
        toShow(){
            this.showIcon = false;
            this.list.forEach((item)=>{
              item.editable = true;
            })
        },
        async toSave(){
          var obj = {"achievement":this.list}
          let res = await this.$store.dispatch('settingSystemEducationAchievementUpdateAction', { achievement: this.list })
          if(res.code == 200){
            this.$message.success('操作成功!')
            this.getList()
            this.showIcon = true
          }else{
            this.$message.error('操作失败!')
          }
        },
        cancalShow(){
          this.showIcon = true
          this.getList()
        },
        handleChange(value, key, column) {
          const newData = [...this.list]
          const target = newData.filter(item => key === item.achievement_id)[0]
          if (target) {
            target[column] = value
            this.list = newData
          }
          
        },
        handleBlur(value, key, column){
          let list = this.list
          list.forEach((item,index)=>{
                if(!item.january){
                  item.january = "0.00"
                }
                if(!item.february){
                  item.february = "0.00"
                }
                if(!item.march){
                  item.march = "0.00"
                }
                if(!item.april){
                  item.april = "0.00"
                }
                if(!item.may){
                  item.may = "0.00"
                }
                if(!item.june){
                  item.june = "0.00"
                }
                if(!item.july){
                  item.july = "0.00"
                }
                if(!item.august){
                  item.august = "0.00"
                }
                if(!item.september){
                  item.september = "0.00"
                }
                if(!item.october){
                  item.october = "0.00"
                }
                if(!item.november){
                  item.november = "0.00"
                }
                if(!item.december){
                  item.december = "0.00"
                }
                item.firstQuarter = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march);
                item.secondQuarter = parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june);
                item.thirdQuarter = parseFloat(item.july) + parseFloat(item.august) + parseFloat(item.september);
                item.fourthQuarter = parseFloat(item.october) + parseFloat(item.november) + parseFloat(item.december);
                item.yeartarget = parseFloat(item.firstQuarter) + parseFloat(item.secondQuarter) + parseFloat(item.thirdQuarter) + parseFloat(item.fourthQuarter)
                item.firstQuarter = item.firstQuarter.toFixed(2);
                item.secondQuarter = item.secondQuarter.toFixed(2);
                item.thirdQuarter = item.thirdQuarter.toFixed(2);
                item.fourthQuarter = item.fourthQuarter.toFixed(2);
                item.yeartarget = item.yeartarget.toFixed(2);
            })
          this.list = list
        },
        handleSelectDepartment(value){
          let departmentId = ''
          if(value.length === 1){
            departmentId = value[0]
          }else{
            departmentId = value[1]
          }
          this.searchParams.search.department = departmentId
        },
        handleSearch(val){

        },
        async toExport(){
            this.exportLoading = true
            let exportData = {
                search:this.searchParams.search,
                sort:this.searchParams.sort
            }
            let res = await this.$store.dispatch('achievementExportAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = `业绩目标.${moment().format('YYYY.MM.DD')}.xls`
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
            this.exportLoading = false
        },
    }
}
</script>